import styled, { css } from 'styled-components'
import {brand, button, error, neutral, stroke, success, text} from '../colors'
import {Disabled} from '../styles'
import PropTypes from 'prop-types'

const propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([
    'large',
    'medium',
    'small',
    'xsmall'
  ]).isRequired,
  style: PropTypes.object,
  type: PropTypes.oneOf([
    'button',
    'submit',
    'reset'
  ]),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'ghost',
    'ghost-neutral',
    'danger',
    'success'
  ]).isRequired,
}

const Button = ({id, children, variant, type, size, disabled, style, onClick}) => {
  return (
    <StyledButton
      id={id}
      style={style}
      onClick={onClick}
      type={type ?? 'button'}
      $disabled={disabled}
      $type={variant}
      $size={size}
    >
      {children}
    </StyledButton>
  )
}

Button.propTypes = propTypes

export default Button

const Large = css`
  border-radius: 8px;
  padding: 0 16px;
  font-size: 14px;
  height: 36px;
`
const Medium = css`
  border-radius: 8px;
  padding: 0 12px;
  font-size: 14px;
  height: 32px;
`
const Small = css`
  ${Medium};
  font-size: 12px;
  height: 28px;
`
const ExtraSmall = css`
  border-radius: 6px;
  padding: 0 8px;
  font-size: 12px;
  height: 24px;
`

const Primary = css`
  background-color: ${brand.primary['50']};
  color: ${text['100']};

  &:hover {
    background-color: ${brand.primary['30']};
  }
  
  &:active {
    background-color: ${brand.primary['10']};
  }
`

const Secondary = css`
  border: 1px solid ${neutral['0A24']};
  background-color: ${button.primary['100']};
  color: ${brand.primary['50']};

  &:hover {
    background-color: ${button.primary['90']};
  }
  
  &:active {
    background-color: ${button.primary['70']};
  }
`

const Tertiary = css`
  border: 1px solid ${neutral['0A24']};
  background-color: ${button.primary['100']};
  color: ${text['0']};

  &:hover {
    background-color: ${stroke['0A16']};
  }
  
  &:active {
    background-color: ${neutral['0A24']};
  }
`

const Ghost = css`
  background-color: transparent;
  color: ${brand.primary['50']};

  &:hover {
    background-color: ${button.primary['90']};
  }

  &:active {
    background-color: ${button.primary['70']};
  }
`

const GhostNeutral = css`
  background-color: transparent;
  color: ${text['0']};

  &:hover {
    background-color: ${stroke['0A16']};
  }
  
  &:active {
    background-color: ${neutral['0A24']};
  }
`

const Danger = css`
  border: 1px solid ${error['50']};
  background-color: ${neutral['0A4']};
  color: ${error['50']};

  &:hover {
    background-color: ${error['100']};
    color: ${error['30']};
  }
  
  &:active {
    background-color: ${error['70']};
    color: ${error['10']};
  }
`

const Success = css`
  border: 1px solid ${success['50']};
  background-color: ${neutral['0A4']};
  color: ${success['50']};

  &:hover {
    background-color: ${success['100']};
    color: ${success['30']};
  }
  
  &:active {
    background-color: ${success['70']};
    color: ${success['10']};
  }
`

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  gap: 6px;
  font-weight: 500;
  border: none;
  font-family: 'DM Sans', sans-serif;
  box-sizing: border-box;
  white-space: nowrap;
  ${Disabled}
  
  ${({$size}) => {
  switch ($size) {
    case 'large':
      return Large
    case 'medium':
      return Medium
    case 'small':
      return Small
    case 'xsmall':
      return ExtraSmall
  }
}}

  ${({$type}) => {
  switch ($type) {
    case 'primary':
      return Primary
    case 'secondary':
      return Secondary
    case 'tertiary':
      return Tertiary
    case 'ghost':
      return Ghost
    case 'ghost-neutral':
      return GhostNeutral
    case 'danger':
      return Danger
    case 'success':
      return Success
  }
}}
`

import styled from 'styled-components'
import PropTypes from 'prop-types'
import {LoadingPlaceholder} from '../styles'
import {neutral, stroke} from '../colors'

const propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  size: PropTypes.string,
  clickable: PropTypes.bool,
  isLoading: PropTypes.bool,
  backgroundColor: PropTypes.string,
  children: PropTypes.node
}

const Avatar = ({id, image, size = '32px', clickable, isLoading, backgroundColor, children}) => {
  return (
    <AvatarContainer
      id={id}
      $size={size}
      $clickable={clickable}
      $isLoading={isLoading}
      $loadingSpace="650px"
      $backgroundColor={backgroundColor}
    >
      {
        !isLoading &&
        <AvatarImage src={image}/>
      }
      {children}
    </AvatarContainer>
  )
}

Avatar.propTypes = propTypes

export default Avatar

const AvatarContainer = styled.div`
  border-radius: 50%;
  max-width: ${({$size}) => $size ?? '32px'};
  max-height: ${({$size}) => $size ?? '32px'};
  min-width: ${({$size}) => $size ?? '32px'};
  min-height: ${({$size}) => $size ?? '32px'};
  border: 3px solid ${({$isLoading}) => $isLoading ? neutral['0A4'] : stroke['0A16']};
  box-sizing: border-box;
  cursor: ${({$clickable}) => $clickable ? 'pointer' : 'default'};
  overflow: hidden;
  
  ${({$backgroundColor}) => $backgroundColor ? `background-color: ${$backgroundColor};` : ''}
  ${({$isLoading}) => $isLoading ? LoadingPlaceholder : ''}
  
  &:hover {
    ${({$clickable}) => $clickable ? 'background-color: rgba(255, 255, 255, 0.08);' : ''}
    ${({$clickable}) => $clickable ? 'border-color: ${stroke50};' : ''}
  }
`

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

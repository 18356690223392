import {useMemo} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {stroke} from '../colors'

const propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  id: PropTypes.string,
  initialOpacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  pill: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object,
  target: PropTypes.string,
}

const CircleButton = ({children, $ref, id, onClick, href, target, size = '28px', pill, style, initialOpacity}) => {
  const CircleComponent = useMemo(() => {
    return typeof href === 'string'
      ? LinkCircle
      : BaseCircle
  }, [onClick, href])

  return (
    <CircleComponent
      ref={$ref}
      id={id}
      onClick={onClick}
      href={href}
      target={target}
      style={style}
      $size={size}
      $pill={pill}
      $initialOpacity={initialOpacity}
    >
      {children}
    </CircleComponent>
  )
}

CircleButton.propTypes = propTypes

export default CircleButton

export const BaseCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ $size, $pill }) => $pill ? 'fit-content' : $size};
  max-width: ${({ $size, $pill }) => $pill ? 'fit-content' : $size};
  height: ${({ $size }) => $size};
  max-height: ${({ $size }) => $size};
  border-radius: ${({$size}) => parseInt($size.replace('px', ''), 10) / 2}px;
  background-color: transparent;
  cursor: pointer;
  opacity: ${({ $initialOpacity }) => $initialOpacity ?? '0.48'};
  
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  
  ${({$pill}) => $pill ? `padding: 0 6px; gap: 5px;` : ''}

  &:hover {
    opacity: 1;
    background-color: ${stroke['0A8']};
  }
`

const LinkCircle = styled(BaseCircle).attrs({ as: 'a' })`
  /* Add additional styles specific to the Link component here */
`


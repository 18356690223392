import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Disabled} from '../styles'
import {brand, neutral, text} from '../colors'

const propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  size: PropTypes.oneOf([
    'xsmall',
    'small',
    'medium',
    'large'
  ]).isRequired,
}

const Toggle = ({active, size, showLabel, disabled, onClick}) => {
  const ToggleComponent = (
    <Container
      $active={active}
      $size={size}
      $disabled={disabled}
      onClick={onClick}
    >
      <Switch
        $active={active}
        $size={size}
      />
    </Container>
  )
  if (showLabel !== false) {
    return (
      <LabelContainer>
        <Label>
          {/* @todo support localization? */}
          {active ? 'ON' : 'OFF'}
        </Label>
        {ToggleComponent}
      </LabelContainer>
    )
  }
  return ToggleComponent
}

Toggle.propTypes = propTypes

export default Toggle

const AnimationLength = 200
const Sizes = {
  large: 28,
  medium: 24,
  small: 20,
  xsmall: 16,
}
const Offsets = {
  large: 2,
  medium: 2,
  small: 1,
  xsmall: 1,
}

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  gap: 8px;
`

const Label = styled.span`
  color: ${text['70']};
  font-size: ${({$size}) => ($size === 'xsmall' || $size === 'small') ? 11 : 12}px;
  width: 24px;
  text-align: right;
`

const Switch = styled.div`
  border-radius: 50%;
  transition: transform ${AnimationLength}ms ease-in-out, background-color ${AnimationLength}ms ease-in-out;
  transform: translateX(${({$active, $size}) => $active ? `${Sizes[$size] }px` : 0});
  background-color: ${({$active}) => $active ? brand.primary['100'] : text['70']};
  width: ${({$size}) => Sizes[$size] - Offsets[$size] * 2}px;
  height: ${({$size}) => Sizes[$size] - Offsets[$size] * 2}px;
`

const Container = styled.div`
  display: flex;
  cursor: pointer;
  transition: background-color ${AnimationLength}ms ease-in-out;
  border-radius: ${({$size}) => Sizes[$size] / 2}px;
  width: ${({$size}) => Sizes[$size] * 2}px;
  height: ${({$size}) => Sizes[$size]}px;
  padding: ${({$size}) => ['xsmall', 'small'].includes($size) ? '1px' : '2px'};
  background-color: ${({$active}) => $active ? brand.primary['50'] : neutral['95']};
  
  &:hover {
    background-color: ${({$active}) => $active ? brand.primary['30'] : 'black'};
    
    ${Switch} {
      background-color: ${({$active}) => $active ? 'black' : text['50']};
    }
  }
  
  ${Disabled}
`

import {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Disabled} from '../styles'
import {brand, neutral, stroke, text} from '../colors'

const propTypes = {
  autoFocus: PropTypes.bool,
  autoResize: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  debounce: PropTypes.number,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  size: PropTypes.oneOf([
    'medium',
    'large'
  ]).isRequired,
  style: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.string,
}

const TextArea = ({id, autoFocus, autoResize, defaultValue, value, type, size = 'large', placeholder, onChange = () => {}, onKeyDown, onClick, onBlur, disabled, debounce, style} = {}) => {
  const [inputValue, setInputValue] = useState(defaultValue ?? '')
  const textareaRef = useRef(null)
  const debounceTimeoutRef = useRef(null)

  const adjustHeight = () => {
    const textarea = textareaRef.current
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight + 3}px`
  };

  const onChangeListener = e => {
    if (value !== undefined) {
      onChange(e)
    } else {
      setInputValue(e.target.value)
      if (!isNaN(debounce)) {
        if (debounceTimeoutRef.current) {
          clearTimeout(debounceTimeoutRef.current)
        }
        debounceTimeoutRef.current = setTimeout(() => {
          onChange(e)
        }, debounce)
      } else {
        onChange(e)
      }
    }

    if (autoResize) {
      adjustHeight()
    }
  }

  useEffect(() => {
    if (autoResize) {
      adjustHeight()
    }
  }, [])

  return (
    <Input
      className="scrollable"
      ref={textareaRef}
      id={id}
      autoFocus={autoFocus}
      placeholder={placeholder}
      value={value !== undefined ? value : inputValue}
      onChange={onChangeListener}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      type={type}
      style={style}
      $size={size}
      $disabled={disabled}
    />
  )
}

TextArea.propTypes = propTypes

export default TextArea

const Input = styled.textarea`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${stroke['0A16']};
  background: ${neutral['0A4']};
  box-shadow: 5px 6px 10px 0 rgba(0, 0, 0, 0.06);
  color: ${text['0']};
  font-family: DM Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  flex-shrink: 0;
  min-height: 50px;
  ${Disabled}

  ${({$size}) => {
  switch ($size) {
    case 'large':
      return 'height: 36px; padding: 8px 16px;'
    case 'medium':
      return 'height: 32px; padding: 8px 12px;'
  }
}}
  
  &:hover {
    border: 1px solid ${stroke['0A16']};
    background: ${stroke['0A8']};
  }
  
  &:focus {
    border: 1px solid ${brand.primary['50']};
    outline: none;
  }
  
`

import {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {background, stroke, text} from '../colors'

// space between tooltip and whatever it's wrapping
const PIXEL_SPACING = 4
const emptyObj = {}

const propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  position: PropTypes.oneOf([
    'top',
    'bottom',
    'left',
    'right'
  ]),
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]),
  wrapperStyle: PropTypes.object,
}

const Tooltip = ({ children, position, tooltip, disabled, wrapperStyle = emptyObj }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const targetRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipVisible) {
      adjustTooltipPosition();
    }
  }, [tooltipVisible]);

  function adjustTooltipPosition() {
    if (tooltip === undefined) {
      return
    }

    const targetRect = targetRef.current.getBoundingClientRect();
    const tooltipElem = tooltipRef.current

    // This will be used to set styles of the tooltip.
    const styles = {
      position: 'fixed',
    };

    // Determine styles based on desired position.
    switch (position) {
      case 'top':
        styles.bottom = window.innerHeight - targetRect.top + PIXEL_SPACING
        styles.left = targetRect.left + (targetRect.width / 2) - (tooltipElem.offsetWidth / 2);
        break;
      case 'bottom':
        styles.top = targetRect.bottom + PIXEL_SPACING;
        styles.left = targetRect.left + (targetRect.width / 2) - (tooltipElem.offsetWidth / 2);
        break;
      case 'left':
        styles.top = targetRect.top;
        styles.right = window.innerWidth - targetRect.left + PIXEL_SPACING
        styles.maxWidth = targetRect.left - (PIXEL_SPACING * 2)

        // if positioned left is too small, position right instead
        if (styles.maxWidth < 200) {
          const maxWidth = window.innerWidth - (targetRect.right + PIXEL_SPACING * 2)
          if (maxWidth > styles.maxWidth) {
            styles.right = undefined;
            styles.left = targetRect.right + PIXEL_SPACING
            styles.maxWidth = maxWidth
          }
        }
        break;
      case 'right':
        styles.top = targetRect.top
        styles.left = targetRect.right + PIXEL_SPACING
        styles.maxWidth = window.innerWidth - (targetRect.right + PIXEL_SPACING * 2)

        // if positioned right is too small, position left instead
        if (styles.maxWidth < 200) {
          const maxWidth = targetRect.left - (PIXEL_SPACING * 2)
          if (maxWidth > styles.maxWidth) {
            styles.left = undefined;
            styles.right = window.innerWidth - targetRect.left + PIXEL_SPACING
            styles.maxWidth = maxWidth
          }
        }
        break;
      default:
        break;
    }

    setTooltipStyle(styles);
  }

  return (
    <Container
      onClick={() => setTooltipVisible(false)}
      onMouseEnter={() => !disabled && setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      ref={targetRef}
      style={wrapperStyle}
    >
      {children}
      {
        tooltip !== undefined &&
        <div ref={tooltipRef} style={{...tooltipStyle, visibility: tooltipVisible ? 'visible' : 'hidden'}} className={`tooltip`}>
          {tooltip}
        </div>
      }
    </Container>
  );
}

Tooltip.propTypes = propTypes

export default Tooltip

const Container = styled.div`
  display: flex;
  position: relative;
  
  .tooltip {
    visibility: hidden;
    display: flex;
    background-color: ${background['first-layer']};
    border: 1px solid ${stroke['0A8']};
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: ${text['0']};
    padding: 12px;
    position: fixed;
    min-width: min-content;
    min-height: min-content;
    width: fit-content;
    height: fit-content;
    white-space: pre-wrap;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  &:hover {
    .tooltip {
      visibility: visible;
    }
  }
`

import PropTypes from 'prop-types'
import {useEffect} from 'react'
import styled from 'styled-components'
import CloseButton from '../CloseButton'
import {background, stroke, text} from '../colors'

const propTypes = {
  bodyPadding: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  exitOnClick: PropTypes.bool,
  exitOnEscape: PropTypes.bool,
  gap: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]),
  id: PropTypes.string.isRequired,
  navigateBackOrHome: PropTypes.func,
  navigateOnClose: PropTypes.oneOf([
    false,
    true,
    'home'
  ]),
  onClose: PropTypes.func,
  position: PropTypes.oneOf(['center', 'top', 'bottom']),
  size: PropTypes.oneOfType([
    PropTypes.oneOf([
      'xsmall',
      'small',
      'medium',
      'large',
      'fullscreen',
    ]),
    PropTypes.string, // This allows any string
  ]),
  zIndex: PropTypes.number,
}

const Modal = ({id, children, size, style, header, exitOnClick, exitOnEscape, onClose, gap, bodyPadding, zIndex, scrollable, closeModal, navigateOnClose, navigateBackOrHome, position}) => {
  const close = (e, force) => {
    const isTargetModalOverlay = e?.target?.id === id
    const shouldClose = isTargetModalOverlay || force
    if (shouldClose) {
      if (typeof closeModal === 'function') {
        closeModal(id)
      }

      if (typeof onClose === 'function') {
        onClose(e, shouldClose)
      }

      // for modals that are opened via routes, it should
      // navigate back or home when the modal is closed
      if (navigateOnClose && typeof navigateBackOrHome === 'function') {
        navigateBackOrHome()
      }
    }
  }

  useEffect(() => {
    const onKeyDown = (e) => {
      if (exitOnEscape === false) {
        return
      }
      e.stopImmediatePropagation()
      if (e.key === 'Escape') {
        close(null, true)
      }
    }
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return (
    <Overlay
      id={id}
      onClick={e => {
        if (exitOnClick !== false) {
          close(e)
        }
      }}
      $position={position}
      $zIndex={zIndex}
    >
      <Container
        className={scrollable !== false ? 'scrollable y' : undefined}
        style={style}
        $size={size}
      >
        <CloseButton onClick={e => close(e, true)}/>
        {
          header &&
          <Header>
            {header}
          </Header>
        }
        <Body
          id={`${id}-body`}
          $padding={bodyPadding}
          $gap={gap}
        >
          {children}
        </Body>
      </Container>
    </Overlay>
  )
}

Modal.propTypes = propTypes

export default Modal

const Overlay = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${({$zIndex}) => $zIndex ?? 2};
  backdrop-filter: blur(4px);
  
  ${({$position}) => {
    if ($position === 'top') {
      return 'justify-content: flex-start; padding: 24px 0;'
    } else if ($position === 'top') {
      return 'justify-content: flex-end; padding: 24px 0;'
    }
  }}
`

const Container = styled.div`
  background-color: ${background['second-layer']};
  border: 1px solid ${stroke['0A8']};
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 3px 36px rgba(0, 0, 0, 0.75);
  position: relative;
  display: flex;
  flex-direction: column;
  ${({$size}) => {
    if ($size === 'xsmall') {
      return 'width: min(400px, 100%);'
    } else if ($size === 'small') {
      return 'width: min(555px, 100%);'
    } else if ($size === 'medium') {
      return 'width: min(700px, 100%);'
    } else if ($size === 'large') {
      return 'width: min(900px, 100%);'
    } else if ($size === 'fullscreen') {
      return 'width: 100%; height: 100%; border-radius: 0;'
    } else {
      return $size
    }
  }}
`

const Header = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: white;
  padding: 0 60px 0 60px;
  border-bottom: 1px solid ${stroke['0A8']};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  text-align: center;
  min-height: 64px;
  height: fit-content;
  flex-shrink: 0;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({$padding}) => $padding ?? '20px'};
  font-size: 14px;
  color: ${text['30']};
  width: 100%;
  height: 100%;
  max-height: calc(100% - 64px);
  ${({$gap}) => $gap ? `gap: ${$gap};` : ''}
`

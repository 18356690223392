import styled from 'styled-components'
import PropTypes from 'prop-types'
import {brand, text, button} from '../colors'

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  position: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const CloseButton = ({size = '22', position, className, onClick = () => {}}) => {
  return (
    <Button
      className={className}
      onClick={e => onClick(e, true)}
      $size={size}
      $position={position}
    >
      <svg width={`${size}`} height={`${size}`} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="circle" d="M10.9998 21.8332C16.9582 21.8332 21.8332 16.9582 21.8332 10.9998C21.8332 5.0415 16.9582 0.166504 10.9998 0.166504C5.0415 0.166504 0.166504 5.0415 0.166504 10.9998C0.166504 16.9582 5.0415 21.8332 10.9998 21.8332Z"/>
        <path id="x" d="M7.93408 14.0657L14.0657 7.93408" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="x" d="M14.0657 14.0657L7.93408 7.93408" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </Button>
  )
}

CloseButton.propTypes = propTypes

export default CloseButton

const Button = styled.button`
  background-color: transparent;
  border: none;
  width: ${({$size}) => $size}px;
  height: ${({$size}) => $size}px;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
  padding: 0;
  margin: 0;
  position: absolute;
  top: ${({$position}) => $position ?? '20px'};
  right: ${({$position}) => $position ?? '20px'};
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.64);
  
  svg {
    #x {
      stroke: ${text['0']};
    }
    #circle {
      fill: #5E5C61;
    }
  }

  &:hover {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.9);
    svg {
      #x {
        stroke: ${brand.primary['50']};
      }
      #circle {
        fill: ${button.primary['90']};
      }
    }
  }
`

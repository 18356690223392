import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import {brand} from '../colors'

const emptyObj = {}

const propTypes = {
  size: PropTypes.string,
  style: PropTypes.object
}

const LoadingSpinner = ({size = '25px', style = emptyObj}) => {
  return (
    <Spinner
      $size={size}
      style={style}
    />
  );
}

LoadingSpinner.propTypes = propTypes

export default LoadingSpinner;

const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  position: relative;
  animation: ${rotate} infinite .75s linear;
  border: 4px solid rgba(0, 0, 0, .2);
  border-left-color: ${brand.primary['50']};
  will-change: transform;
  border-radius: 50%;
  width: ${({$size}) => $size};
  height: ${({$size}) => $size};
  min-width: ${({$size}) => $size};
  min-height: ${({$size}) => $size};
  max-width: ${({$size}) => $size};
  max-height: ${({$size}) => $size};
`

import styled, { css, keyframes } from 'styled-components'
import {error, neutral, stroke, success, text} from './colors'

export const Disabled = css`
  ${({$disabled}) => {
  if ($disabled) {
    return 'opacity: 0.35; pointer-events: none;'
  }
}}
`

const loadingPlaceholderAnimation = keyframes`
  0% { background-position: -650px 0; }
  100% { background-position: 650px 0; }
`

export const LoadingPlaceholder = css`
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ${loadingPlaceholderAnimation};
  background: linear-gradient(to right, ${({$backgroundColor}) => $backgroundColor || neutral['0A4']} 2%, ${({$loadingOverlayColor}) => $loadingOverlayColor || stroke['0A8']} 18%, ${({$backgroundColor}) => $backgroundColor || neutral['0A4']} 33%);
  background-size: ${({$loadingSpace}) => $loadingSpace ?? '1300px'}; // Animation Area
`

export const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: ${text['30']};
  font-size: 12px;
  font-weight: 500;
`

export const ErrorText = styled.span`
  color: ${error['10']};
`

export const SuccessText = styled.span`
  color: ${success['10']};
`

export default {
  Disabled,
  LoadingPlaceholder,
  InputLabelWrapper,
  SuccessText,
  ErrorText,
}

import PropTypes from 'prop-types'
import styled from 'styled-components';
import {background, brand, button, neutral, stroke} from '../colors'

const propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

const Checkbox = ({
  className,
  checked,
  disabled,
  onChange
}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} onChange={onChange}/>
    <StyledCheckbox $checked={checked} onClick={() => onChange({target: {checked: !checked}})} $disabled={disabled}>
      <Icon width="12" height="9" viewBox="0 0 12 9">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.12903 8.21331C5.39368 8.99555 4.16386 8.99555 3.4285 8.21331L0.710429 5.32193C0.283329 4.8676 0.299817 4.14773 0.747255 3.71405C1.19469 3.28037 1.90365 3.29711 2.33075 3.75144L4.77877 6.35554L9.67043 1.152C10.0975 0.697667 10.8065 0.680926 11.2539 1.11461C11.7014 1.54829 11.7178 2.26816 11.2907 2.72249L6.12903 8.21331Z"
        />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

Checkbox.propTypes = propTypes

export default Checkbox;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg``

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: ${({$checked, $disabled}) => {
    if ($checked) {
      return $disabled ? button.primary['90'] : brand.primary['50']
    } else {
      return $disabled ? neutral['0A4'] : background['third-layer']
    }
  }};
  border: ${({$checked, $disabled}) => ($checked ? 'none' : `1px solid ${$disabled ? stroke['0A8'] : stroke['50']}`)};
  box-sizing: border-box;
  border-radius: 4px;
  transition: background-color 150ms, border 150ms;
  cursor: ${({$disabled}) => $disabled ? 'default' : 'pointer'};
  ${({$disabled}) => $disabled ? 'pointer-events: none;' : ''}

  ${Icon} {
    visibility: ${({$checked}) => ($checked ? 'visible' : 'hidden')};
    fill: ${({$checked, $disabled}) => $checked ? ($disabled ? brand.primary['70'] : brand.primary['100']) : brand.primary['50']};
  }
  
  &:hover {
    background-color: ${({$checked}) => ($checked ? brand.primary['30'] : stroke['0A16'])};
    ${Icon} {
      visibility: visible;
    }
  }
`

import {useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {background, stroke, text} from '../colors'

const propTypes = {
  alignment: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
  children: PropTypes.node,
  items: PropTypes.array.isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
  triggerId: PropTypes.string.isRequired,
  wrapperStyle: PropTypes.object
}

const ContextMenu = ({ children, triggerId, position, alignment, items, wrapperStyle }) => {
  const [isOpen, setOpen] = useState(false)
  const containerRef = useRef()

  const onClickListener = useCallback(e => {
    setOpen(!isOpen)
  }, [isOpen])

  const onClickOutsideListener = useCallback(e => {
    const el = containerRef.current
    if (isOpen && el && !el.contains(e.target)) {
      setOpen(false)
    }
  }, [isOpen])

  useEffect(() => {
    const el = document.getElementById(triggerId)
    el.addEventListener('click', onClickListener)
    return () => {
      el.removeEventListener('click', onClickListener)
    }
  }, [onClickListener])

  useEffect(() => {
    document.addEventListener('click', onClickOutsideListener)
    return () => {
      document.removeEventListener('click', onClickOutsideListener)
    }
  }, [onClickOutsideListener])

  return (
    <Container
      ref={containerRef}
      style={wrapperStyle}
    >
      {children}
      {
        isOpen &&
        <MenuContainer
          $position={position}
          $alignment={alignment}
        >
          {
            items.map((item, index) => {
              if (item.divider) {
                return <MenuDivider key={`divider-${index}`}/>
              }
              return (
                <MenuItem
                  key={`menu-item-${index}`}
                  $isLink={typeof item.label === 'object' && item.label.type === 'a'}
                  onClick={e => {
                    setOpen(false)
                    item?.onClick?.(e)
                  }}
                >
                  {item.label}
                </MenuItem>
              )
            })
          }
        </MenuContainer>
      }
    </Container>
  )
}

ContextMenu.propTypes = propTypes

export default ContextMenu

const Container = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`

const MenuContainer = styled.div`
  position: absolute;
  background-color: ${background['third-layer']};
  border-radius: 12px;
  border: 1px solid ${stroke['0A16']};
  box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.5);
  z-index: 1;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  ${({ $position, $alignment }) => {
    const positions = {
      top: { bottom: 'calc(100% + 4px)', [$alignment]: '0' },
      bottom: { top: 'calc(100% + 4px)', [$alignment]: '0' },
      left: { right: 'calc(100% + 4px)', [$alignment]: '0' },
      right: { left: 'calc(100% + 4px)', [$alignment]: '0' },
    };
    return positions[$position] || '';
  }}
`

const MenuItem = styled.div`
  padding: ${({$isLink}) => $isLink ? '0' : '10px 12px'};
  font-size: 14px;
  font-weight: 500;
  color: ${text['30']};
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  gap: 8px;
  
  a {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 12px !important;
    color: ${text['30']};
    
    &:hover {
      text-decoration: none;
    }
  }
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`

const MenuDivider = styled.div`
  width: 100%;
  min-height: 1px;
  background-color: ${stroke['0A16']};
`
